import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "../../style/startInstantMeeting.module.css";

const MeetingIdAndDate = () => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isMobileScreen) {
    return null; 
  }

  return (
    <Box className={styles.meetingIdAndDate} display="flex">
      <Typography color="text.primary">1:44 PM</Typography>
      <Typography color="text.primary" className={styles.dividerIcon}>
        |
      </Typography>
      <Typography color="text.primary">mvy-utwc-xrv</Typography>
    </Box>
  );
};

export default MeetingIdAndDate;
