import * as React from "react";
import { Box, IconButton, Stack, Typography, MenuItem, Tooltip, Menu } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Modal from "@mui/material/Modal"; // Import the Modal component
import Setting from "./Setting";
import ReportSideMini from "./ReportSideMini";

const LeftSideDrawer = ({ toggleDrawer, open }) => {
  // Define anchorElSupport, openSupportMenu, closeSupportMenu, settingMenu, and closeSetting
  const [anchorElSupport, setAnchorElSupport] = React.useState(null);
  const openSupportMenu = (event) => setAnchorElSupport(event.currentTarget);
  const closeSupportMenu = () => setAnchorElSupport(null);
  const [settingMenu, setSettingMenu] = React.useState(false);
  const openSetting = () => setSettingMenu(true);
  const closeSetting = () => setSettingMenu(false);
  const [drawerReportOpen, setDrawerReportOpen] = React.useState(false);
  const toggleReportDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerReportOpen(open);
  };

  return (
    <>
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: "260px" }} p={1}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
          
            <Box
              display={{ xs: "flex", lg: "flex" }}
              flexDirection="column"
              alignItems="start"
            >
              <IconButton sx={{ mx: 1 }} onClick={openSupportMenu}>
                <Tooltip title={"Support"}>
                  <HelpOutlineIcon /> Help
                </Tooltip>
              </IconButton>
              <Menu
               
                anchorEl={anchorElSupport}
                open={Boolean(anchorElSupport)}
                onClose={closeSupportMenu}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Box sx={{ width: 200 }}>
                  <MenuItem>
                    <Typography>Help</Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography>Training</Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography>Terms of Services</Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography>Privacy Policy</Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography>Terms Summary</Typography>
                  </MenuItem>
                </Box>
              </Menu>
              <IconButton sx={{ mx: 1 }} onClick={toggleReportDrawer(true)}>
                <Tooltip title={"Report a problem"}>
                  <ReportOutlinedIcon /> Report
                  
                </Tooltip>
              </IconButton>
              <IconButton onClick={openSetting} sx={{ mx: 1 }}>
                <Tooltip title={"Setting"}>
                  <SettingsIcon /> Settings
                  <ReportSideMini open={drawerReportOpen} toggleDrawer={toggleReportDrawer} />
                </Tooltip>
              </IconButton>
            </Box>
         
        </Box>
      </Drawer>
      <Modal
        open={settingMenu}
        onClose={closeSetting}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <Setting />
        </>
      </Modal>
    </>
  );
};

export default LeftSideDrawer;
