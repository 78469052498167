import * as React from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Grid, IconButton, Tooltip, Button, Menu, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import LeftSideDrawer from "./MenuLeftDrawer";
import ReportSideDrawer from "./ReportSideDrawer";
import { useTheme } from "../style/theme";
import { useMenu } from "../hooks/useMenu";
import useModal from "../hooks/useModal";
import Image from "./Image";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const profilePictureTitle = (
  <>
    <Typography variant="caption" lineHeight="15px" fontWeight={500}>
      Clikkle Account
    </Typography>
    <Typography variant="caption" component="div" lineHeight="15px" color="#dce1e7">
      First Name
    </Typography>
    <Typography variant="caption" component="div" lineHeight="15px" color="#dce1e7">
      username@gmail.com
    </Typography>
  </>
);

const Navbar = ({ children }) => {
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [currentTime, setCurrentTime] = React.useState(new Date());
  const { toggleTheme, mode } = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerLeftOpen, setDrawerLeftOpen] = React.useState(false);
  const {
    anchorEl: anchorElSupport,
    openMenu: openSupportMenu,
    closeMenu: closeSupportMenu,
  } = useMenu();

  const {
    state: settingMenu,
    openModal: openSetting,
    closeModal: closeSetting,
  } = useModal();

  React.useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleLeftDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerLeftOpen(open);
  };

  // Get hours and minutes from the current time
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();

  // Check if the current path includes '/leftmeeting/'
  const isLeftMeetingPage = location.pathname.includes("/leftmeeting/");

  return (
    <>
      {!isLeftMeetingPage && (
        <ReportSideDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      )}
      <Box
        sx={{
          px: { xs: 0.5, xm: 0 },
          height: "100dvh",
          position: "relative",
          
        }}
      >
        {!isLeftMeetingPage && (
          <AppBar
            elevation={0}
            component={Box}
            position="sticky"
            sx={{ bgcolor: "background.default", color: "inherit" }}
          >
            <Toolbar
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                "&": {
                  minHeight: "64px",
                  px: 0,
                  width:
                    isMobile &&
                    (location.pathname.startsWith("/awaitingmeetingjoin/") ||
                      location.pathname.startsWith("/joinbylink/"))
                      ? "200%"
                      : isLGScreen
                      ? "100%"
                      : "auto",
                },
              }}
            >
              <Grid container alignItems="center" display="flex" sx={{borderBottom: '1px solid #3f3f46',}}>
                <Grid item xs>
                  <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    component={Link}
                    to="/"
                    sx={{
                      textDecoration: "none",
                      color: "text.primary",
                      py: 0.5,
                      px: { xs: 0, lg: 2 },
                    }}
                  >
                    <IconButton onClick={toggleLeftDrawer(true)}>
                      <MenuIcon />
                    </IconButton>
                    <Image
                      src="https://cdn.clikkle.com/images/crew/logo/2023/crew.png"
                      sx={{ height: "32px", width: "32px", marginLeft: "12px" }}
                    />
                    <Typography
                      color="#f4f4f5"
                      fontWeight="bold"
                      fontSize={{ xs: "18px", lg: "30px" }}
                      paddingLeft={{ xs: "5px", lg: "8px" }}
                    >
                      Clikkle
                    </Typography>
                    <Typography
                      color="#f4f4f5"
                      fontSize={{ xs: "18px", lg: "30px" }}
                      paddingLeft={{ xs: "5px", lg: "8px" }}
                    >
                      Crew
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={0}
                  >
                    <Box display={{ xs: "none", lg: "none" }}>
                      <Typography
                        variant="body1"
                        color="text.tertiary"
                        sx={{
                          mt: 1.3,
                        }}
                      >
                        {`${hours}:${minutes < 10 ? "0" : ""}${minutes}`}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.tertiary"
                        sx={{ mx: 2, mt: 1.3 }}
                      >
                        {new Date().toDateString()}
                      </Typography>
                    </Box>
                    {!isMobile && (
                      <>
                        <Link to="https://accounts.clikkle.com/register?redirectto=https%3A%2F%2Fapps.clikkle.com%2F">
                        <Button sx={{ mr: 1, color: "#f4f4f5" }}>
                        Sign In</Button></Link>
                        <Link to="https://accounts.clikkle.com/register?redirectto=https%3A%2F%2Fapps.clikkle.com%2F">
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            mr: 2,
                            borderColor: "neutral.800",
                            bgcolor: "neutral.800",
                            color: "white",
                            px:2, py:1
                          }}
                        >
                       
                           Join a Meeting

                        </Button></Link>
                         <Link to="https://accounts.clikkle.com/register?redirectto=https%3A%2F%2Fapps.clikkle.com%2F">
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mr: 3, px:2, py:1 }}
                        >
                          Start a Meeting
                        </Button></Link>
                      </>
                    )}
                    <IconButton
                      sx={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: "primary.main",
                        p: "3px",
                        mr:{xs:2, lg:5},
                        
                      }}
                    >
                      <Tooltip title={profilePictureTitle}>
                        <Avatar
                          alt="Remy Sharp"
                          src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          sx={{
                            cursor: "pointer",
                            width: 25,
                            height: 25,
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        )}
        <Box
          component="main"
          sx={{
            px: 2,
            mt: 1,
            height: { xs: "calc(100dvh - 90px)" },
          }}
        >
          {children}
        </Box>
      </Box>
      <LeftSideDrawer open={drawerLeftOpen} toggleDrawer={toggleLeftDrawer} />
    </>
  );
};

export default Navbar;
